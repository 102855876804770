import React from "react"
import "../sass/_404.scss"
import "../components/layout.scss"
import { SubpageLayout } from "../components/layout"
import SEO from "../components/seo"

const PaymentComplete = () => (
  <SubpageLayout>
    <SEO title="Payment Successful" />
    <div className="container h-200">
      <div className="row h-100 align-items-left justify-content-left text-left">
        <div className="col-lg-6 col-md-8 col-sm-8 col-8 align-self-end" style={{marginTop: "100px"}}>
          <h3 className="text-uppercase text-white font-weight-bold" style={{paddingLeft: "15px"}}>
            Thank you
          </h3>
        </div>
      </div>
    </div>
    <div className="container h-200">
    <div className="col-lg-6 col-md-8 col-sm-8 col-8 align-self-baseline">
        <div className="body">
          Your payment was completed Successfully.
        </div>
      </div>
    
      
    </div>
    
    
  </SubpageLayout>
)

export default PaymentComplete
